*{
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body{
  background-color: #fff;
  overflow-wrap: break-word;
}

.site-wrapper{
  font-family: Source Code Pro;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0em;
  text-transform: none;
  line-height: 1.7em;
  font-size: 1rem;
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  min-height: 100vh;
}

h1{
  font-style: normal;
  letter-spacing: 0em;
  text-transform: none;  color: #bd1900;
  font-weight: 400;
  font-family: 'Abel', sans-serif;
  font-size: 20px;
  font-size: calc((2 - 1) * 1.2vw + 1rem);
  line-height: 34px;
  margin: 0px;
}

h2{
  font-family: 'Abel', sans-serif;
  font-weight: 400;
  font-size: calc((2.8 - 1) * 1.2vw + 1rem);
  color: white;
}

h3{
  font-family: 'Abel', sans-serif;
  font-weight: 400;
  font-size: calc((2.4 - 1) * 1.2vw + 1rem);

}

h4{
  font-family: 'Abel', sans-serif;
  font-weight: 400;
  font-size: calc((1.6 - 1) * 1.2vw + 1rem);
}

.footer{
  display: flex;
  justify-content: baseline;
  align-items: baseline;
}

.navigation-bar{
  /* padding: 6vw; */
  padding-top: 3vw;
  padding-bottom: 2.5vw;
  padding-left: 4vw;
  padding-right: 1vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;

}

.sub-nav{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 480px;
}

img{
  /* margin: 0.8vw; */
  width: 100%;
  height: auto;
  /* cursor:zoom-in; */
  
}

a{
font-family: 'Source Code Pro', monospace;  font-weight: 300;
font-size: 15px;
line-height: 1.7em;
text-decoration: none;
color: black;
}

#test{
  text-decoration: underline;
}

.mobile-nav{
  display: flex;
  flex-direction: column;
  padding-top: 22vh;    
  align-items: center;
  height: 90vh;
  width: 100vw;
  position: fixed;
  z-index: 2;
  background-color: #fff;
  top: 10vh;
  animation: fadein 0.5s;
  will-change: opacity, top;



}

@keyframes fadein {
  from { opacity: 0; top: 30vh; }
  to   { opacity: 1; top: 10vh; }
}



p{
  font-family: 'Source Code Pro', monospace;  font-weight: 300;
  line-height: 25.5px;
  font-size: 15px;

}

li{
  font-family: 'Source Code Pro', monospace;  font-weight: 300;
  line-height: 25.5px;
  font-size: 15px;

}

.mn-text{
  font-size: 2rem;
}

#insta-logo{
  height: auto;
  width: 24px;
}

.insta-mobile-logo{
  width: 64px;
  width: 40px;
  bottom: 7rem;
  position: absolute;
  /* animation: instafadein 0.7s; */

}

/* #insta-open{
  animation: instafadein 0.7s;
} */

/* #insta-close{
  animation: instafadeout 0.7s;
} */

/* @keyframes instafadein {
  from { bottom: 1rem; }
  to   { bottom: -3rem; }
} */

.mobile{
display: none;
}

.total{
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
  background-color: rgba(250, 235, 215, 0.223);
}

.row-var1{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 4vw;
}

.row-var2{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  padding: 4vw;
}

.about-pic{
  width: 45vw;
  height: auto;
  object-fit: cover;
}

.text-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 4rem;
}

.text-box2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 4rem;
}

@media only screen and (max-width: 800px) {
  .mobile{
     display:block;
  }
  .desktop{
    display: none;
  }
  .navigation-bar{
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 600px) {
  h1{
    font-size: 2rem;
    padding-top: 18px;
    margin-right: 70px;
  }
  .photo-button{
    padding-right: 6vw;
    padding-top: 1.5rem;
  }
}

@media only screen and (max-width: 500px) {
  .row-var1{
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .row-var2{
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .about-pic{
    width: 90vw;
  }
  .text-box{
    padding-right: 1vw;
    padding-left: 2.5vw;
  }
  .text-box2{
    padding-right: 1vw;
    padding-left: 2.5vw;
  }
}


@media only screen and (max-width: 420px) {
  .mobile-nav{
    height: 85vh;
    top:15vh;
    animation: fadein-420 0.5s;
  }
}

@keyframes fadeout-420 {
  from { opacity: 1; top: 10vh; }
  to   { opacity: 0; top: 15vh; }
}
@keyframes fadein-420 {
  from { opacity: 0; top: 30vh; }
  to   { opacity: 1; top: 15vh; }
}

@media only screen and (max-width: 275px) {
  .mobile-nav{
    height: 82vh;
    top:18vh;
    animation: fadein-275 0.5s;
  }
  .mn-text{
    font-size: 1rem;
  }
  #close{
    animation: fadeout-275 0.5s;
  }
}

@keyframes fadeout-275 {
  from { opacity: 1; }
  to   { opacity: 0; top: 30vh; }
}
@keyframes fadein-275 {
  from { opacity: 0; top: 30vh; }
  to   { opacity: 1; top: 18vh; }
}

@media only screen and (min-width: 800px) {
  .mobile-display{
     display:none;
  }
}



/* font-family: 'Source Code Pro', monospace;  font-weight: 300; */



/* Hamburger CSS */

/* Icon 1 */

#nav-icon1 {
  width: 30px;
  height: 30px;
  position: absolute;
  margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  top: -10px;
  right: 40px;
  will-change: top, opacity;

}

#nav-icon1 span {
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  background: #000000;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  will-change: top, opacity;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 10px;
}

#nav-icon1 span:nth-child(3) {
  top: 20px;
}

#nav-icon1.open span:nth-child(1) {
  top: 10px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#nav-icon1.open span:nth-child(3) {
  top: 10px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

#close{
  animation: fadeout 0.5s;
  transition: 0.5s;
  visibility: hidden;
  will-change: opacity, top;
}


@keyframes fadeout {
  from { opacity: 1;  }
  to   { opacity: 0; top: 30vh; }
}

#inv-test{
  animation: invtest 1s;
  opacity: 0;
  animation-fill-mode: forwards;
}

@keyframes invtest {
  from { opacity: 0 }
  to   { opacity: 1; }
}

.photo-overlay{
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.89);
  z-index: 4;
  top:0px;
  left: 0px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: calc(6vw + 20px) 6vw;
  animation: photoOver 0.25s;
}

@keyframes photoOver {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.photo-wrapper{
  position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.photo-header{
  position: absolute;
  width: 100vw;
  padding: 1.5vw 1.5vw 0;
  display: flex;
  justify-content: flex-end;
  left: 0;
  z-index: 15;
  top: 0px;
}

.photo-button{
  border-radius: 0.15em;
  box-sizing: border-box;
  pointer-events: auto;
  opacity: .75;
  transition: opacity 600ms cubic-bezier(.4,0,.2,1);
  border: none;
  margin: 0;
  background: transparent;
  cursor:pointer;
}

.photo-item{
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  height: 85vh;
  object-position: 50% 50%;
  object-fit: contain;
  position: absolute;
  z-index: 10;
  padding: 2rem;
}

#bp_container img{
  object-fit: contain;
  box-shadow: none !important;
}

#contactback{
  width: 100vw;
  height: 95vh;
  object-fit: cover;
}

.photo-wrapper{
  position: absolute;
  height: 100%;
  width: 100%;
}

.getstuck{
  overflow: hidden;
  height: 100vh;
}

.right-click{
  width: 50vw;
  height: 100vh;
  position: absolute;
  right: 0px;
  z-index: 11;
  display: flex;
  justify-content: flex-end;
  padding-right: 2vw;
}

.left-click{
  width: 50vw;
  height: 100vh;
  position: absolute;
  left: 0px;
  z-index: 11;
  display: flex;
  justify-content: flex-start;
  padding-left: 2vw;
}

.image-buffer770{
  display: none;
  visibility: hidden;
}

@media only screen and (max-width: 770px) {
  .image-buffer770{
    display: block;
  }
}

.lazy-wrapper{
  height: 500px;
  position: relative;
    display: block;
}


 .grid {
    display: block;
    width: 100%;
  } 
  .grid-item {
    position: relative;
    width: 100%;
    border: 0;
    display: block;
    background-color: #fff;    border: 0;
    background-size: cover;
    transition: opacity 0.2s;
  }

 /* .grid {
    display: block;
    width: 100%;
  } */
  /* .grid-item {
    position: relative;
    width: 100%;
    border: 0;
    display: block;
    background-color: #f1f1f1;
    border: 0;
    background-size: cover;
    transition: opacity 0.2s;
  }
  .grid-item:hover {
    opacity: 0.94;
  }
  .gi-padding {
    width: 100%;
  }
  .asub{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  } */

  #bp_count{
    display: none !important;
  }